var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "blog-details-area ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-8 col-md-12" }, [
          _c("div", { staticClass: "blog-details-desc" }, [
            _c(
              "div",
              { staticClass: "article-content" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.spaceName))]),
                _c(
                  "b-tabs",
                  { attrs: { align: "center" } },
                  [
                    _c(
                      "b-tab",
                      {
                        staticClass: "nva-title",
                        attrs: { "no-body": "", title: _vm.$t("ul.title_1") }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "article-image" },
                          [
                            _c(
                              "b-carousel",
                              {
                                staticStyle: {
                                  "text-shadow": "1px 1px 2px #333"
                                },
                                attrs: {
                                  id: "carousel-1",
                                  interval: 3000,
                                  controls: "",
                                  indicators: "",
                                  background: "#ababab",
                                  "img-width": "1024",
                                  "img-height": "720"
                                }
                              },
                              _vm._l(_vm.spaceDetail.picture, function(
                                item,
                                index
                              ) {
                                return _c("b-carousel-slide", {
                                  key: index,
                                  attrs: { "img-src": item.path }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _vm.spaceDetail.map_path != undefined &&
                    _vm.spaceDetail.map_path.length != 0
                      ? _c(
                          "b-tab",
                          {
                            staticClass: "nva-title",
                            attrs: {
                              "no-body": "",
                              title: _vm.$t("ul.title_2")
                            }
                          },
                          [
                            _c("iframe", {
                              staticStyle: { border: "0" },
                              attrs: {
                                src:
                                  "https://www.google.com/maps/embed?" +
                                  _vm.spaceDetail.map_path,
                                width: "100%",
                                height: "500",
                                allowfullscreen: "",
                                loading: "lazy"
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c("p", {
                  staticClass: "blog-content-text mt-4",
                  domProps: { innerHTML: _vm._s(_vm.spaceDescription) }
                })
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "col-lg-4 col-md-12" }, [
          _c(
            "div",
            { staticClass: "widget-area", attrs: { id: "secondary" } },
            [
              _c(
                "div",
                { staticClass: "widget" },
                [
                  _c("h3", { staticClass: "widget-title" }, [
                    _vm._v(_vm._s(_vm.$t("rightBody.title_1")))
                  ]),
                  _c("div", { staticClass: "post-wrap" }, [
                    _c(
                      "ul",
                      [
                        _c("li", [
                          _c("span", [
                            _c("i", { staticClass: "far fa-file-alt" }),
                            _vm._v(_vm._s(_vm.$t("rightBody.title_2")))
                          ]),
                          _c(
                            "p",
                            { staticClass: "space-content" },
                            _vm._l(_vm.spaceDetail.spaceTag, function(
                              item,
                              index
                            ) {
                              return _c(
                                "b-badge",
                                { key: index, attrs: { variant: "primary" } },
                                [_vm._v(_vm._s(item.tag_name))]
                              )
                            }),
                            1
                          )
                        ]),
                        _c("li", [
                          _c("span", [
                            _c("i", { staticClass: "fas fa-vector-square" }),
                            _vm._v(_vm._s(_vm.$t("rightBody.title_3")))
                          ]),
                          _c("p", { staticClass: "space-content" }, [
                            _vm._v(_vm._s(_vm.spaceDetail.area) + " m"),
                            _c("sup", [_vm._v("2")])
                          ])
                        ]),
                        _c("li", [
                          _c("span", [
                            _c("i", { staticClass: "fas fa-ruler-combined" }),
                            _vm._v(_vm._s(_vm.$t("rightBody.title_4")))
                          ]),
                          _c("p", { staticClass: "space-content" }, [
                            _vm._v(_vm._s(_vm.spaceDetail.height) + " m")
                          ])
                        ]),
                        _vm.spaceDetail.deviceTag != undefined &&
                        _vm.spaceDetail.deviceTag.length != 0
                          ? _c("li", [
                              _c("span", [
                                _c("i", {
                                  staticClass: "fas fa-chalkboard-teacher"
                                }),
                                _vm._v(_vm._s(_vm.$t("rightBody.title_5")))
                              ]),
                              _c(
                                "p",
                                { staticClass: "space-content" },
                                _vm._l(_vm.spaceDetail.deviceTag, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "b-badge",
                                    {
                                      key: index,
                                      attrs: { variant: "primary" }
                                    },
                                    [_vm._v(_vm._s(item.tag_name))]
                                  )
                                }),
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._l(_vm.flexContent, function(item, index) {
                          return _c("li", { key: index }, [
                            _c("span", [
                              _c("i", { staticClass: "far fa-list-alt" }),
                              _vm._v(_vm._s(Object.keys(item)[0]))
                            ]),
                            _c("p", { staticClass: "space-content" }, [
                              _vm._v(_vm._s(item[Object.keys(item)[0]]))
                            ])
                          ])
                        })
                      ],
                      2
                    )
                  ]),
                  _c(
                    "router-link",
                    { staticClass: "contact", attrs: { to: "/contact-us" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            block: "",
                            variant: "outline-primary",
                            size: "lg"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "far fa-calendar-check mr-2"
                          }),
                          _vm._v(_vm._s(_vm.$t("rightBody.btn")))
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "widget widget_tag_cloud" }, [
                _c("h3", { staticClass: "widget-title" }, [
                  _vm._v(_vm._s(_vm.$t("rightBody.title_6")))
                ]),
                _c("div", { staticClass: "post-wrap pt-0" }, [
                  _c(
                    "div",
                    { staticClass: "tagcloud" },
                    _vm._l(_vm.spaceDetail.activityTag, function(item, index) {
                      return _c("a", { key: index }, [
                        _vm._v(_vm._s(item.tag_name) + " "),
                        _c("i", { staticClass: "fas fa-users" }),
                        _vm._v(_vm._s(item.capacity) + " 人")
                      ])
                    }),
                    0
                  )
                ])
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }