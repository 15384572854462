import { render, staticRenderFns } from "./RentalVenueSpaceMain.vue?vue&type=template&id=5e806a03&"
import script from "./RentalVenueSpaceMain.vue?vue&type=script&lang=js&"
export * from "./RentalVenueSpaceMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/ctweb/fontend/kecc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e806a03')) {
      api.createRecord('5e806a03', component.options)
    } else {
      api.reload('5e806a03', component.options)
    }
    module.hot.accept("./RentalVenueSpaceMain.vue?vue&type=template&id=5e806a03&", function () {
      api.rerender('5e806a03', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DefaultHome/KECC/Organizers/RentalVenueSpace/RentalVenueSpaceMain.vue"
export default component.exports