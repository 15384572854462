<template>
    <div class="blog-details-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="blog-details-desc">
                        <div class="article-content">
                            <h3>{{spaceName}}</h3>
                            <b-tabs align="center">
                                <b-tab class="nva-title" no-body :title="$t('ul.title_1')">
                                    <div class="article-image">
                                        <b-carousel id="carousel-1" :interval="3000" controls indicators
                                            background="#ababab" img-width="1024" img-height="720"
                                            style="text-shadow: 1px 1px 2px #333;">
                                            <b-carousel-slide :img-src="item.path"
                                                v-for="(item, index) in spaceDetail.picture" :key="index">
                                            </b-carousel-slide>
                                        </b-carousel>
                                    </div>
                                </b-tab>

                                <b-tab class="nva-title" no-body :title="$t('ul.title_2')" v-if="spaceDetail.map_path != undefined && spaceDetail.map_path.length != 0">
                                    <iframe :src="`https://www.google.com/maps/embed?${spaceDetail.map_path}`"
                                        width="100%" height="500" style="border:0;" allowfullscreen=""
                                        loading="lazy"></iframe>
                                </b-tab>

                            </b-tabs>
                            <p class="blog-content-text mt-4" v-html="spaceDescription">
                            </p>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="widget-area" id="secondary">
                        <div class="widget">
                            <h3 class="widget-title">{{ $t('rightBody.title_1') }}</h3>
                            <div class="post-wrap">
                                <ul>
                                    <li>
                                        <span><i class="far fa-file-alt"></i>{{ $t('rightBody.title_2') }}</span>
                                        <p class="space-content">
                                            <b-badge variant="primary" v-for="(item, index) in spaceDetail.spaceTag"
                                                :key="index">{{item.tag_name}}</b-badge>
                                        </p>
                                    </li>
                                    <li>
                                        <span><i class="fas fa-vector-square"></i>{{ $t('rightBody.title_3') }}</span>
                                        <p class="space-content">{{spaceDetail.area}} m<sup>2</sup></p>
                                    </li>
                                    <li>
                                        <span><i class="fas fa-ruler-combined"></i>{{ $t('rightBody.title_4') }}</span>
                                        <p class="space-content">{{spaceDetail.height}} m</p>
                                    </li>
                                    <li v-if="spaceDetail.deviceTag != undefined && spaceDetail.deviceTag.length != 0">
                                        <span><i class="fas fa-chalkboard-teacher"></i>{{ $t('rightBody.title_5') }}</span>
                                        <p class="space-content">
                                            <b-badge variant="primary" v-for="(item, index) in spaceDetail.deviceTag"
                                                :key="index">{{item.tag_name}}</b-badge>
                                        </p>
                                    </li>
                                    <li v-for="(item, index) in flexContent" :key="index">
                                        <span><i class="far fa-list-alt"></i>{{ Object.keys(item)[0] }}</span>
                                        <p class="space-content">{{ item[Object.keys(item)[0]] }}</p>
                                    </li>
                                </ul>
                            </div>
                            <router-link to="/contact-us" class="contact">
                                <b-button block variant="outline-primary" size="lg"><i
                                        class="far fa-calendar-check mr-2"></i>{{ $t('rightBody.btn') }}</b-button>
                            </router-link>
                        </div>
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">{{ $t('rightBody.title_6') }}</h3>
                            <div class="post-wrap pt-0">
                                <div class="tagcloud">
                                    <a v-for="(item, index) in spaceDetail.activityTag" :key="index">{{item.tag_name}}
                                        <i class="fas fa-users"></i>{{item.capacity}} 人</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const apiUrl = `${process.env.VUE_APP_API_URL}/venue`;
    import * as CLE from "assets/plugins/ClickLinkEvent";
    export default {
        name: 'RentalVenueSpaceBody',
        metaInfo() {
            return {
                title: this.spaceName,
            }
        },
        data() {
            return {
                spaceDetail: {},
                spaceName: '',
                spaceDescription: '',
                flexContent: ''
            }
        },
        mounted() {
            this.getSpaceDetail();
            const space_id = this.$route.params.space_id
            CLE.addClickLinkCount(this, "space/" + space_id, "venue", "venue_space_id", space_id)
        },
        methods: {
            getSpaceDetail() {
                const spaceId = this.$route.params.space_id;
                const api = `${apiUrl}/space?venue_space_id=${spaceId}&language_id=${this.$i18n.locale}`;
                this.axios.get(api).then((response) => {

                    this.spaceDetail = response['data'];
                    this.spaceName = this.spaceDetail.space_name[this.$i18n.apiLang];
                    this.spaceDescription = this.spaceDetail.space_description[this.$i18n.apiLang];
                    let content = '';
                    try {
                        content = JSON.parse(this.spaceDetail['flex_content'][this.$i18n.apiLang]);
                    } catch (e) {
                        // Oh well, but whatever...
                    }
                    this.flexContent = (Array.isArray(content)) ? content : [];

                    this.spaceDetail.spaceTag = [];
                    this.spaceDetail.activityTag = [];
                    this.spaceDetail.deviceTag = [];

                    this.spaceDetail.tag.forEach(tagElement => {
                        if (tagElement.tag_group != "") {
                            let tagType = tagElement.tag_group.split(',')[0];
                            let tagLang = tagElement.tag_group.split(',')[1];
                            if(this.$i18n.locale == tagLang)
                            {
                                switch (tagType) {
                                    case "場域類別":
                                        this.spaceDetail.spaceTag.push(tagElement);
                                        break;
                                    case "活動類型":
                                        this.spaceDetail.activityTag.push(tagElement);
                                        break;
                                    case "設備與服務":
                                        this.spaceDetail.deviceTag.push(tagElement);
                                        break;
                                }
                            }
                        }
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .contact {
        display: block;
    }

    .widget li {
        position: relative;
        padding-bottom: 10px;
        margin-bottom: 10px;
        color: #666666;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #e4e4e4;
    }

    .blog-details-area .widget-area .tagcloud a:hover {
        background-color: #33ccff;
    }

    .blog-details-area .widget-area .tagcloud a {
        font-size: 16px !important;
    }

    .badge-primary {
        padding: 8px 10px;
        margin-right: 5px;
        font-size: 14px;
        font-weight: 500;
    }

    .post-wrap i {
        padding-right: 6px;
    }

    .blog-details-area .blog-details-desc .article-content .entry-meta ul li span {
        color: #000066;
        font-size: 14px;
    }

    .blog-details-area .blog-details-desc .article-content h3 {
        color: #000033;
        font-size: 30px;
        text-transform: none;
    }

    .blog-content-text {
        color: #929292;
    }

    .space-content {
        margin-top: 5px;
        color: #929292;
        padding-left: 20px;
    }

    .widget-area {
        margin-top: 35px;
    }

    .badge-info {
        background-color: #33ccff
    }

    .blog-details-area .widget-area .widget_categories ul li {
        font-weight: normal;
    }

    .blog-details-area .widget-area .widget_categories ul li::before {
        background: #33ccff;
    }
</style>